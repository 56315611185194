<template>
  <div id="app"></div>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {
      screenWidth: null,
    };
  },
  watch: {
    screenWidth(val) {
      if (val > 1220 && !location.pathname.includes("/pc/")) {
        location.href = location.origin + "/pc/";
      }
      if (val <= 1220 && !location.pathname.includes("/mobile/")) {
        location.href = location.origin + "/mobile/";
      }
    },
  },
  mounted () {
    this.screenWidth = document.body.clientWidth
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth
      })
    }
  },
  created() {
    // document.body.removeChild(document.getElementById('Loading'))
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
